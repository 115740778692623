import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import Logo from '@/components/icons/logo';
import { getURL } from '@/utils/helpers';
import dynamic from 'next/dynamic';
import CenteredSpinner from '@/components/ui/centered-spinner';

export async function getServerSideProps(context: any) {
  if (process.env.NEXT_PUBLIC_IS_MAINTENANCE === 'true') {
    return {
      redirect: {
        permanent: false,
        destination: '/maintenance'
      }
    };
  } else {
    return {
      props: {}
    };
  }
}

const Auth = dynamic(
  () => import('@supabase/auth-ui-react').then((mod) => mod.Auth),
  {
    ssr: false,
    loading: () => <div />
  }
);

const SignIn = () => {
  const router = useRouter();
  const user = useUser();
  const supabaseClient = useSupabaseClient();

  useEffect(() => {
    if (user) {
      router.replace('/');
    }
  }, [user]);

  return (
    <section>
      {!user ? (
        <div className="flex flex-col justify-center items-center min-h-screen max-w-lg p-3 m-auto w-80 align-middle">
          <div>
            <div className="flex justify-center">
              <img src="/logo.png" alt="AlphaCTR" width={128} />
            </div>
            <h6 className="leading-7 mt-2 mb-4 text-center text-gray-400">
              Create one thumbnail with AlphaCTR for free, no credit card
              required.
            </h6>
          </div>
          <Auth
            supabaseClient={supabaseClient}
            providers={['google']}
            onlyThirdPartyProviders={true}
            redirectTo={getURL()}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    defaultButtonText: 'white',
                    defaultButtonBackground: '#334155',
                    defaultButtonBorder: '#334155',
                    defaultButtonBackgroundHover: '#64748b'
                  }
                }
              }
            }}
            dark
            theme="default"
            localization={{
              variables: {
                sign_in: {
                  social_provider_text: 'Continue with Google'
                }
              }
            }}
          />
          <span className="text-slate-400 text-xs text-center">
            By signing in, you agree to our{' '}
            <a
              href="https://alphactr.com/terms"
              className="text-gray-200 hover:text-slate-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://alphactr.com/privacy-policy"
              className="text-slate-200 hover:text-slate-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </span>
        </div>
      ) : (
        <CenteredSpinner />
      )}
    </section>
  );
};
export default SignIn;
